import Swal from "sweetalert2/dist/sweetalert2.js";
import store from "../store";
import i18n from "./i18n";

export default {
  install(Vue) {
    Vue.prototype.$global = {
      ///////// Input Required Rules /////////
      requiredRule: [(v) => !!v || i18n.t("Field is required")],
      ///////// Input Email Required Rules /////////
      emailRule: [
        (v) => !!(v || "").match(/@/) || i18n.$t("Please enter email address"),
      ],
      ///////// Input Mobile Required Rules /////////
      mobileRule: [
        (v) =>
          !!(v || "").match(/^(?:[+0]9)?[0-9]{10,12}$/) ||
          i18n.t("Please enter mobile number"),
      ],
      ///////// Input Password Required Rules /////////
      passwordRule: [
        (v) => !!v || i18n.t("Password is required"),
        (v) =>
          (v && v.length >= 5) ||
          i18n.t("Password must be greater than 5 characters"),
      ],

      /////////  Array /////////
      FilterPermissions(Screen_Code) {
        if (store.state.permissions.length > 0) {
          var find = store.state.permissions.find(
            (obj) => obj["screen_code"] === Screen_Code
          );
          return find || Object;
        }
      },
      CheckAction(Screen_Code, Action) {
        var find = store.state.permissions.filter(
          (obj) =>
            obj["screen_code"] === Screen_Code &&
            (obj["permission"].includes(Action.toString()) ||
              obj["permission"].includes(Number(+Action)))
        );
        return find.length > 0 ? true : false;
      },
      FilterArray(ARRAY, filterBy) {
        return ARRAY.filter((find) => find === filterBy);
      },
      FilterArrayOfObject(ARRAY, SearchIn, filterBy) {
        return ARRAY.filter((obj) => obj[SearchIn] === filterBy);
      },
      FilterArrayOfObjectElement(
        ARRAY,
        SearchIn,
        filterBy,
        ReturnElement = null
      ) {
        const filter = ARRAY.filter((obj) => obj[SearchIn] === filterBy);
        return filter.length ? filter.at(0)[ReturnElement] : null;
      },
      FilterObjectByArray(OBJECT, ARRAY) {
        return ARRAY.reduce((obj, key) => ({ ...obj, [key]: OBJECT[key] }), {});
      },
      RemoveFromArray(ARRAY, SearchIn, VALUE) {
        const removeIndex = ARRAY.findIndex((find) => find[SearchIn] === VALUE);
        return ARRAY.splice(removeIndex, 1);
      },
      ARRAY_REDUCE(ITEMS, KEY) {
        return ITEMS.reduce((last_result, active_item) => {
          return +last_result + (+active_item[KEY] || 0);
        }, 0);
      },
      /////////   Calculate pagenation  /////////
      CalculateNumberOfPages(TOTAL, LIMIT) {
        return Math.ceil(TOTAL / LIMIT);
      },
      // Dates
      GetCurrentTime() {
        var time = new Date();
        return time.getHours() + ":" + time.getMinutes();
      },
      GetCurrentDate() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        return [year, month, day].join('-');
      },

      GetFirstDayOfMonth() {
        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        const firstDay = new Date(d.getFullYear(), d.getMonth(), 2);
        return firstDay.toISOString().split('T')[0];
      },
      SubtractDates(FIRST_DATE, SECOND_DATE) {
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
        return Math.ceil(timeDiff / (1000 * 3600 * 24));

      },
      SubtractFromDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() - Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      AddToDate(DATE, VALUE) {
        const date = new Date(DATE);
        date.setDate(date.getDate() + Number(VALUE));
        return date.toISOString().split('T')[0];
      },
      AddMonthsToDate(DATE, VALUE = 1) {
        const date = new Date(DATE);
        var calculated = new Date(date.setMonth(date.getMonth() + VALUE));
        return calculated.toISOString().split('T')[0];
      },
      GetDaysBetween(FIRST_DATE, SECOND_DATE) {
        console.log(FIRST_DATE, SECOND_DATE);
        var getDaysArray = function (start, end) {
          for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
          }
          return arr;
        };
        var dayList = getDaysArray(new Date(FIRST_DATE), new Date(SECOND_DATE));
        var dates = dayList.map((v) => v.toISOString().slice(0, 10)).join(",");
        return dates.split(',').map(i => i);
      },
      GetMonthsBetween(FIRST_DATE, SECOND_DATE) {
        var dateFirst = new Date(FIRST_DATE.replace("-", "/"));
        var dateSecond = new Date(SECOND_DATE.replace("-", "/"));
        var diff = (dateSecond.getTime() - dateFirst.getTime()) / 1000;
        diff /= (60 * 60 * 24 * 7 * 4);
        return Math.abs(Math.round(diff));
      },
      FILE_TYPE(FILE) {
        // Base64
        // console.log(FILE);
        if (String(FILE).substring(0, 5) == "data:") {
          // console.log(
          //   "FILE TYPE =>",
          //   FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
          // );
          switch (FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.oasis.opendocument.spreadsheet":
              return "excel";
            case "text/csv":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            default:
              // return FILE.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
              return "notFound";
          }
        }
        // Normal
        else {
          // return FILE ? FILE.split('.').pop() : '';
          switch (FILE.type) {
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
              return "excel";
            case "application/vnd.ms-excel":
              return "excel";
            case "application/vnd.oasis.opendocument.spreadsheet":
              return "excel";
            case "text/csv":
              return "excel";
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              return "word";
            case "application/octet-stream":
              return "rar";
            case "application/x-zip-compressed":
              return "zip";
            case "application/json":
              return "json";
            case "application/pdf":
              return "pdf";
            case "image/png":
              return "png";
            case "image/jpg":
              return "jpg";
            case "image/jpeg":
              return "jpeg";
            case "text/plain":
              return "txt";
            case "image/svg+xml":
              return "svg";
            case "image/gif":
              return "gif";
            default:
              var n = FILE.lastIndexOf(".");
              var result = FILE.substring(n + 1);
              return result;
          }
        }
      },
      /////////  Numbers /////////
      NumberWithSpaces(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      ConvertToPercentage(PartialValue, TotalValue) {
        return ((100 * PartialValue) / TotalValue).toFixed(2);
      },
      ConvertFromPercentage(Percent, Total) {
        return ((Percent / 100) * Total).toFixed(2);
      },
      CalculatePriceTax(Price, Tax) {
        return {
          price: Number(+Price),
          tax: Number(+Tax),
          priceAfterTax: Number(+Price) + Number(+Price) * Number(+Tax),
          pricePercentage: 100 - 100 * Number(+Tax),
          taxPercentage: 100 * Number(+Tax),
        };
      },
      ConvertToArabicNumber(NUMBER) {
        return (NUMBER.prototype.EntoAr = function () {
          return this.replace(/\d/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d]);
        });
      },
      RyalFormatter(NUMBER) {
        var formatter = new Intl.NumberFormat("ar", {
          style: "currency",
          currency: "",
        });

        return formatter.format(NUMBER);
      },
      DecimalFormatter(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      Currency(NUMBER) {
        return NUMBER.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      },
      NumberWithCommas(NUMBER) {
        return NUMBER.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      IncrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            find[ATTRIBUTE] = find[ATTRIBUTE] + 1;
          }
        });
      },
      DecrementInArrayOfObject(ARRAY, ID, ATTRIBUTE) {
        return ARRAY.forEach((find) => {
          if (find.id === ID) {
            if (find[ATTRIBUTE] > 1) {
              find[ATTRIBUTE] = find[ATTRIBUTE] - 1;
            }
          }
        });
      },

      ///////// ToggleView /////////

      // ToggleView(ElementID) {
      //   var element = document.getElementById(ElementID);
      //   if (element.style.display === "none") {
      //     element.style.opacity = 1;
      //     element.style.display = "block";
      //   } else {
      //     element.style.display = "none";
      //   }
      // },
      CRYPT(KEY, TEXT) {
        // 1
        let textEncrypt = Vue.prototype.$CryptoJS.enc.Base64.stringify(
          Vue.prototype.$CryptoJS.enc.Utf8.parse(TEXT)
        );

        return textEncrypt;

        // 2
        // var x  = Vue.prototype.$CryptoJS.enc.Hex.parse(KEY);
        // var iv   = Vue.prototype.$CryptoJS.enc.Hex.parse('00000000000000000000000000000000');
        // var encrypted = Vue.prototype.$CryptoJS.AES.encrypt(TEXT, x, {iv: iv});
        // return encrypted.toString();
      },
      DECRYPT(KEY, ENCODED) {
        try {
          let textDecrypt = Vue.prototype.$CryptoJS.enc.Base64.parse(ENCODED).toString(Vue.prototype.$CryptoJS.enc.Utf8);
          return textDecrypt;
        } catch (error) {
          console.error("Decryption error:", error);
          return false;
        }
      },
      ///////// Modal /////////
      ShowModal(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: i18n.t(TITLE),
          text: i18n.t(MSG),
          toast: false,
          timer: 3000,
          showConfirmButton: false,
        });
      },
      ///////// Alert Toast /////////
      ShowAlert(TYPE, TITLE, MSG) {
        Swal.fire({
          icon: TYPE,
          title: i18n.t(TITLE),
          text: i18n.t(MSG),
          toast: true,
          timer: 3000,
          timerProgressBar: true,
          position: store.state.Settings.translation.rtl == true ? "top-start" : "top-end",
          showConfirmButton: false,
          showCloseButton: true,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.getHtmlContainer().addEventListener("mouseleave", () => {
              Swal.resumeTimer();
            });
            Swal.getHtmlContainer().addEventListener("mouseover", () => {
              Swal.stopTimer();
            });
          },
        });
      },
      sendNotification(TITLE = 'Qarat - POS', CONTENT) {
        let OPTIONS = {
          body: i18n.t(CONTENT),
          data: '',
          icon: require('../assets/maskable_icon_x48.png'),
          dir: 'rtl',
          // image : require('../assets/maskable_icon_x512.png'),
          // requireInteraction: true,
          silent: false,
          vibrate: [200, 100, 200]
        }
        function checkPermission() {
          return Notification.requestPermission().then(status => status === 'granted');
        }
        function senNotification() {
          checkPermission().then(isAllowed => {
            if (!isAllowed) {
              return
            }
            new Notification(i18n.t(TITLE), OPTIONS)
          })
        }
        senNotification()
      },
      goldConverter(c_18 = 0, c_21 = 0, c_24 = 0, c_22 = 0) {
        let data = { c_18: 0, c_21: 0, c_22: 0, c_24: 0 };
        data['c_18'] = c_18 + (c_21 * 21 / 18) + (c_22 * 22 / 18) + (c_24 * 24 / 18);
        data['c_21'] = (c_18 * 18 / 21) + c_21 + (c_22 * 22 / 21) + (c_24 * 24 / 21);
        data['c_22'] = (c_18 * 18 / 22) + (c_21 * 21 / 22) + c_22 + (c_24 * 24 / 22);
        data['c_24'] = (c_18 * 18 / 24) + (c_21 * 21 / 24) + (c_22 * 22 / 24) + c_24;
        return data;
      },
      goldCalibration(original_caliber = 0, calibrated_caliber = 0, original_weight = 0) {
        const calibrated_weight = (original_caliber * original_weight) / calibrated_caliber
        switch (original_caliber) {
          case 18: case 21: case 22: case 24:
            switch (calibrated_caliber) {
              case 18: case 21: case 22: case 24:
                return +calibrated_weight.toFixed(2) || 0
              default:
                return 0
            }
          default:
            return 0
        }
      },
      PaginateArray(ARRAY, PAGE_SIZE, PAGE_NUMBER) {
        return ARRAY.slice((PAGE_NUMBER - 1) * PAGE_SIZE, PAGE_NUMBER * PAGE_SIZE);
      },
      async openCamera() {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: { width: 1280, height: 720 },
          })
          .then((mediaStream) => {
            const video = document.querySelector("video");
            video.srcObject = mediaStream;
            video.onloadedmetadata = () => {
              video.play();
            };
          })
          .catch((err) => {
            // always check for errors at the end.
            console.error(`${err.name}: ${err.message}`);
          });
      },
      GregorianToHijri(gregorianDate) {
        var now = new Date(gregorianDate)
        var dayOfYear = Math.floor((new Date(gregorianDate) - new Date(now.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24))
        var hijriDate = ((now.getFullYear() - 621.5643) * 365.24225 + dayOfYear) / 354.36707
        var hijriYear = Math.floor(hijriDate)
        var hijriMonth = Math.ceil((hijriDate - Math.floor(hijriDate)) * 354.36707 / 29.530589)
        var hijriDay = Math.floor((hijriDate - Math.floor(hijriDate)) * 354.36707 % 29.530589)
        return `${hijriYear}-${hijriMonth}-${hijriDay}`
      },
      HijriToGregorian(hijriDate) {
        var options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          calendar: 'islamic'
        };
        var formatter = new Intl.DateTimeFormat('en-US', options);
        var formattedDate = formatter.format(new Date(hijriDate));
        var [month, day, year] = formattedDate.split('/');
        console.log(Number(year), Number(month) - 1, Number(day));
        return new Date(Number(year), Number(month) - 1, Number(day));

      },
      GET_USER_LOCATION() {
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                resolve({ latitude, longitude });
              },
              (error) => {
                reject(`Error: ${error.message}`);
              }
            );
          } else {
            reject("Geolocation is not supported by this browser.");
          }
        });
      },
      getPayments(payments, checkKey) {
        const paymentTypes = [
          { id: 1, name: i18n.t("cash") },
          { id: 2, name: i18n.t("network") },
          { id: 3, name: i18n.t("Transfer") },
          { id: 7, name: i18n.t("tabby") },
          { id: 8, name: i18n.t("tamara") },
          { id: 9, name: i18n.t("nearpay") },
          { id: 10, name: i18n.t("STC Pay") },
          { id: 11, name: i18n.t("salla") },
        ];
      
        // Ensure `payments` is always an array
        if (!payments) {
          return [{ id: "-", name: "-" }];
        }
      
        if (typeof payments === "string") {
          payments = payments.split(",").map(Number).filter(num => !isNaN(num));
        }
      
        if (typeof payments === "number") {
          payments = [payments];
        }
      
        if (Array.isArray(payments) && payments.every(p => typeof p === "number")) {
          payments = payments.map(payment => ({ payment_type: payment }));
        }
      
        const result = payments
          .map(payment => {
            const paymentId = payment?.payment_type || payment?.[checkKey];
      
            if (paymentId === 0 || paymentId === null || paymentId === undefined) {
              return { id: "-", name: "-" };
            }
      
            return paymentTypes.find(type => type.id == paymentId) || { id: "-", name: "-" };
          })
          .filter(Boolean)
          .map(({ id, name }) => ({ id, name }));
      
        return result.length ? result : [{ id: "-", name: "-" }];
      }

    };
  },
};
