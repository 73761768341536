<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center py-0">
          <div class="subtitle-2 text--disabled">
            <strong>{{ $t('sales') }} . {{ $t("invoiceList") }}</strong>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="saleBy"
            createBtnLabel="createSalesInvoice" :printURL="$store.state.permissions.includes('06-001') ? 'export_invoice_sales_qty' : 'export_invoice_sales_qty'
              " :data="computedRows" :header="header">

            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'm_id'">
                <v-btn @click="() => { salesInvoiceDialog = true; invoice_id = row.m_id; doc_code = row.m_id }"
                  class="text-decoration-underline body-2 font-weight-bold info--text ma-1" small text>
                  {{ row.m_id }}
                </v-btn>
              </div>
              <div v-if="header.key == 'actions'" class="d-flex align-center py-1">
                <!-- Zatca -->
                <v-tooltip v-if="zatca_app" bottom color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <img v-bind="attrs" v-on="on" class="ma-1" :class="[{ 'black-image': !row?.zatca }]"
                      src="@/assets/img/png/ZatcaActive.png" alt="Zatca" width="30" />
                  </template>
                  <span>{{ row?.zatca ? $t('Sent to zatca') : $t('Not sent to zatca') }}</span>
                </v-tooltip>
                <v-btn @click="$router.push(`/sales/sales-invoice/${row.m_id}`)"
                  v-if="$store.state.userData.super_admin == 1 && !row?.zatca" class="green12 ma-1" x-small icon fab>
                  <v-icon color="green1">mdi-pen</v-icon>
                </v-btn>
                <v-btn @click="() => { salesInvoiceDialog = true; invoice_id = row.m_id }" class="blue12 ma-1" x-small
                  icon fab>
                  <v-icon color="blue1">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn v-if="whatsapp_templates.length" :loading="printLoading && row.m_id == invoice_id"
                  @click="printMethod(row, 'whatsapp')" x-small icon fab>
                  <v-icon color="success">mdi-whatsapp</v-icon>
                </v-btn>
                <v-btn :loading="printLoading && row.m_id == invoice_id" @click="printMethod(row)" x-small icon fab>
                  <v-badge color="success" overlap :content="String(+row.print_count)">
                    <img src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
                  </v-badge>
                </v-btn>
              </div>
            </template>

          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination && computedRows.length > 0">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>


        <PrintTemplatesModal :dialog="printDialog" :print_templates="print_templates" :backValueMethod="closeDialog"
          :invoice_id="invoice_id" :has_whatsapp="has_whatsapp" />
        <PrintTemplatesModal :dialog="whatsPrintDialog" :print_templates="whatsapp_templates"
          :backValueMethod="closeDialog" :invoice_id="invoice_id" :has_whatsapp="has_whatsapp" />

        <SalesInvoiceModal :dialog="salesInvoiceDialog" :canEdit="false" :backValueMethod="closeDialog"
          :invoice_id="invoice_id" :doc_code="doc_code" :zatca_app="zatca_app" />

      </v-row>
    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import PrintTemplatesModal from "@/components/modals/PrintTemplatesModal.vue";
import Pagination from "@/components/Pagination";
import SalesInvoiceModal from "@/components/modals/SalesInvoiceModal.vue";
export default {
  name: "SalesTable",

  data: () => ({
    isLoading: true,
    saleBy: '/sales/create-sales-invoice',
    rows: [],
    header: [],
    pagination: null,
    printDialog: false,
    page: 1,
    rowsNumber: 25,
    searchText: '',
    from: null,
    to: null,
    date: null,
    print_templates: [],
    invoice_id: null,
    selectedInvoice: {},
    doc_code: null,
    printLoading: false,
    has_whatsapp: false,
    whatsPrintDialog: false,
    salesInvoiceDialog: false,
    whatsapp_templates: [],
  }),
  components: {
    DynamicTable,
    Pagination,
    PrintTemplatesModal,
    SalesInvoiceModal
  },
  computed: {
    zatca_app() {
      return this.$store.state?.userData?.zatca_app?.check
    },
    computedRows() {
      this.rows.forEach(row => {
        switch (row?.customer_invoice_type_id) {
          case 1:
            row.invoice_type_name = this.$t('cash customer')
            row.td_3 = 'green8'
            break;
          case 2:
            row.invoice_type_name = this.$t('vat invoice for customer')
            row.td_3 = 'green8'
            break;
          case 3:
            row.invoice_type_name = this.$t('Late invoice for customer')
            row.td_3 = 'red6 white--text'
            break;
          case 4:
            row.invoice_type_name = this.$t('late invoice for supplier')
            row.td_3 = 'red6 white--text'
            break;
          default: // for old invoices
            row.invoice_type_name = row.is_supplier ? this.$t('late') : this.$t('cash')
            row.td_3 = row.is_supplier ? 'red6 white--text' : 'green8'
            break;
        }
      });
      return this.rows
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "m_id", type: 'text', classes: "" },
        { text: "customerName", key: "customer_name", type: 'text', classes: "" },
        { text: "customerMobile", key: "customer_mobile", type: 'text', classes: "" },
        { text: "payment type", key: "payment_type_title", type: 'text', classes: "" },
        { text: "invoice type", key: "invoice_type_name", type: 'text' },
        { text: "vat", key: "grand_total_vat", type: 'price', classes: "" },
        { text: "finalCash", key: "final_total", type: 'price', classes: "" },
        { text: "date", key: "invoice_date", type: 'date', classes: "" },
        { text: "branch", key: "branch_name", type: 'text', classes: "" },
        { text: "added by", key: "user_full_name", type: 'text', classes: "" },
        { text: "actions", key: "actions", type: 'slot', classes: "" },
      ]
    },
    getData(loading = true) {
      this.isLoading = loading;
      if (this.$global.CheckAction("06-001", 1)) {
        this.saleBy = "/sales/create-sales-invoice"
        this.$api.GET_METHOD(`get_sales_invoices_by_qty?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&date=${this.date || ''}`).then((response) => {
          this.isLoading = false;
          this.rows = response.data.data.data;
          this.page = response.data.data.current_page;
          this.print_templates = response.data.prints || [];
          this.whatsapp_templates = response.data.whats_prints || [];
          this.has_whatsapp = response.data.has_whatsapp || false;
          this.pagination = {
            page: response.data.data.current_page,
            totalPages: response.data.data.last_page,
            per_page: response.data.data.per_page,
            totalRows: response.data.data.total,
          };
        })
      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    printMethod(row, type) {
      if (type == 'whatsapp') {
        if (this.whatsapp_templates.length > 1) {
          this.invoice_id = row.m_id
          this.doc_code = row.doc_code
          this.whatsPrintDialog = true;
        }
      }
      else {
        this.invoice_id = row.m_id
        this.doc_code = row.m_id
        this.printDialog = true;
      }
    },
    closeDialog() {
      this.printDialog = false;
      this.salesInvoiceDialog = false;
      this.whatsPrintDialog = false;
      this.invoice_id = null
      this.doc_code = null
      this.getData(false);
    },
  },
};
</script>
<style scoped>
.black-image {
  filter: invert(100%) grayscale(100%);
}
</style>