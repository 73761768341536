<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="pt-0">
          <UsedPurchaseForm :details="details" :sales_agents="sales_agents" :selectedAgent="selectedAgent"
            :master="master" :paymentsType="paymentsType" :isLoading="isLoading" :identity_types="identity_types" :nationalities="nationalities"
            :machines="machines" :banks="banks"/>
        </v-col>
        <v-col cols="12" md="6" class="py-0" v-if="!isLoading">
          <v-card :disabled="master.active == 0" class="white rounded-lg shadow pa-5 overflow-y-scroll"
            :max-height="$store.state.Settings.windowSize.y - 300">
            <v-row v-if="items.length">
              <v-col v-for="(item, index) in items" class="pa-2" :key="index" cols="6" md="4" lg="3" xl="2">
                <button @click="openDetail(item, 'new')" v-ripple class="itemBtn">
                  {{ item.sub_category_title }} - {{ item.category_title }}
                </button>
              </v-col>
            </v-row>
            <v-col cols="12" class="pa-0 mt-5" v-else>
              <v-alert type="info" outlined>
                <span v-if="goldCaliber == 26">{{ $t("notAvail") }} {{ $t("services") }} {{ $t("here") }}</span>
                <span v-else>{{ $t("notAvail") }} {{ $t("items") }} {{ $t("here") }}</span>
              </v-alert>
            </v-col>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 mt-n4" v-if="details.length">
          <DynamicTable :hideExtinctions="true" :isLoading="false" :data="details"
            :tableHeight="$store.state.Settings.windowSize.y - 300" :showHeader="false" :header="header"
            :footerData="[computedItem]">
            <template v-slot:td="{ row, index, header }">
              <div v-if="header.key == 'actions'">
                <v-btn :disabled="master.active == 0" @click="openDetail(row, 'edit', index)" color="blue1" icon small
                  text>
                  <v-icon>mdi-pencil-circle</v-icon>
                </v-btn>
                <v-btn :disabled="master.active == 0" @click="removeDetail(index)" color="error" icon small text>
                  <v-icon>mdi-delete-circle</v-icon>
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" md="6" class="mt-n3" v-else>
          <v-card class="white rounded-lg shadow pa-5 overflow-y-scroll" v-if="!isLoading"
            :max-height="$store.state.Settings.windowSize.y - 300">
            <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('no items added') }}</v-alert>
          </v-card>
        </v-col>
      </v-row>

      <v-row justify="center" style="height: 90vh;" align="center" v-if="isLoading">
        <v-col cols="auto">
          <v-progress-circular :size="70" :width="5" style="margin: auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular>
          <p class="text-center">{{ $t('Loading data') }}</p>
        </v-col>
      </v-row>
    </v-container>

    <UsedItemModal :key="modalKey" :dialog="detailDialog" :detail="activeDetail" :gold_price="gold_price"
      :closeDialog="() => { detailDialog = false; activeDetail = {} }" :setDetail="setDetail" :editDetail="editDetail"
      :use_balance="$store.state.userData.user.branch_data.weighing_machine ? true : false" />

  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import UsedPurchaseForm from "./UsedPurchaseForm.vue";
import UsedItemModal from "@/components/modals/UsedItemModal.vue";
export default {
  name: "PurchasesInvoices",

  data: () => ({
    goldCaliber: 18,
    modalKey: 1,
    sub_category_id: null,
    subCategories: [],
    items: [],
    sales_agents: [],
    categoryID: "",
    toggle_caliber: 1,
    isLoading: true,
    gold_price: { price_18: 0, price_21: 0, price_22: 0, price_24: 0, price_925: 0 },
    selectedAgent: {},
    header: [],
    master: { id: 0 },
    details: [],
    identity_types: [],
    nationalities: [],
    machines: [],
    banks: [],
    paymentsType: [],
    detailDialog: false,
    activeDetail: Object,
    editDetail: false,
    exitDialog: false,
    saveLoading: false,
  }),
  components: {
    UsedPurchaseForm,
    DynamicTable,
    UsedItemModal,
  },
  computed: {
    filteredItems() {
      return this.items.filter(item => item.category_id == this.goldCaliber)
    },
    selectedItem() {
      return this.items.find(item => item.sub_category_id == this.sub_category_id)
    },
    computedItem() {
      return {
        price: this.$global.ARRAY_REDUCE(this.details || [], 'price'),
        weight: this.$global.ARRAY_REDUCE(this.details || [], 'weight'),
        used_weight: this.$global.ARRAY_REDUCE(this.details || [], 'used_weight'),
        diff_weight: this.$global.ARRAY_REDUCE(this.details || [], 'diff_weight'),
        other_materials_weight: this.$global.ARRAY_REDUCE(this.details || [], 'other_materials_weight'),
      }
    }
  },
  watch: {
    'selectedItem.sub_category_id'() {
      this.goldCaliber = this.selectedItem.category_id
    },
    detailDialog() {
      if (!this.detailDialog) {
        this.modalKey = this.modalKey + 1
        this.editDetail = false;
        this.activeDetail = {};
      }
    }
  },
  mounted() {
    if (!this.$global.CheckAction("04-008", 1)) {
      this.$router.push('/login')
    } else {
      if (this.$route.params.id) {
        this.isLoading = true;
        this.$api.GET_METHOD(`get_used_purchase/${this.$route.params.id}`).then((response) => {
          this.isLoading = false;
          this.gold_price = response.data.gold_price
          this.subCategories = response.data.sub_categorys
          this.items = response.data.items || [];
          this.sales_agents = response.data.sales_agents || [];
          this.identity_types = response.data.identity_types || [];
          this.nationalities = response.data.nationalitys || [];
          this.machines = response.data.machines || [];
          this.banks = response.data.banks || [];
          response.data.details.forEach(detail => {
            detail.weight = detail.raw_weight
            detail.price = detail.total_price
          });
          this.details = response.data.details || [];
          response.data.item.payments = response.data.payments || [];
          response.data.item.machine_id = response.data.payments.filter(({ machine_id }) => machine_id)[0]?.machine_id || 0;
          response.data.item.bank_id = response.data.payments.filter(({ bank_id }) => bank_id)[0]?.bank_id || 0;
          this.master = response.data.item || {};
          let hijriDate = this.master.birth_date_h?.split('/')?.reverse()?.join('-');
          this.master.birth_date_h = hijriDate
          this.master.id_number = response.data.item.id_number || response.data.item.customer_id_number || 0;
          this.selectedAgent = {
            id: this.master.sales_agent_id,
            name: this.master.sales_agent_name || this.sales_agents.find(s => s.id == this.master.sales_agent_id)?.name || null,
          }
          this.paymentsType = response.data.item.payments
        })
      } else {
        this.$api.GET_METHOD("get_create_used_invoice_data").then((response) => {
          this.isLoading = false;
          this.gold_price = response.data.gold_price
          this.subCategories = response.data.sub_categorys
          this.items = response.data.items || [];
          this.identity_types = response.data.identity_types || [];
          this.machines = response.data.machines || [];
          this.banks = response.data.banks || [];
          this.nationalities = response.data.nationalitys || [];

          this.sales_agents = response.data.sales_agents || [];
          this.selectedAgent = response.data.sales_agents.length ? response.data.sales_agents[0] : Object
          this.sub_category_id = response.data.items.length ? response.data.items[0].sub_category_id : null
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        })
      }
    }

    this.header = [
      { text: "#", key: "index", type: 'index', classes: "" },
      { text: "caliber", key: "category_id", type: 'text', classes: "" },
      { text: "item", key: "sub_category_title", type: 'text', classes: "" },
      // { text: "description", key: "description", type: 'text', classes: "" },
      { text: "price", key: "price", type: 'float', classes: "" },
      { text: "weight before cracking", key: "weight", type: 'weight', classes: "" },
      { text: "lobes weight", key: "other_materials_weight", type: 'weight', classes: "" },
      { text: "estimated weight", key: "diff_weight", type: 'weight', classes: "" },
      { text: "weight after cracking", key: "used_weight", type: 'weight', classes: "" },
      { text: "actions", key: "actions", type: 'slot', classes: "" },
    ];

  },
  methods: {
    openDetail(detail, type, index) {
      this.activeDetail = {}
      if (type == 'new') {
        let newDetail = Object;
        newDetail.d_id = 0;
        newDetail.category_id = detail.category_id;
        newDetail.sub_category_title = detail.sub_category_title;
        newDetail.sub_category_id = detail.sub_category_id;
        this.activeDetail = { ...newDetail };
        this.detailDialog = true;
        this.editDetail = false;
      } else {
        this.activeDetail = { ...detail };
        this.activeDetail.index = index;
        this.detailDialog = true;
        this.editDetail = true;
      }
    },
    setDetail(detail) {
      if (this.editDetail) {
        console.log(detail);
        this.details[detail.index] = { ...detail }
        this.details = [...this.details]
      } else {
        this.details.push({ ...detail })
      }
      this.activeDetail = {}
      this.detailDialog = false;
      this.editDetail = false;
    },
    removeDetail(index) {
      this.details.splice(index, 1);
    },
  },
};
</script>
