<template>
  <section>
    <v-container fluid>
      <v-row justify="space-between">
        <v-col cols="12" sm="auto" class="d-flex justify-center align-center pt-0 pb-2">
          <v-row align="center" justify="center" justify-md="start">
            <v-col cols="12" md="auto">
              <div class="subtitle-2 text-center text--disabled">
                <strong>{{ $t('gold permission') | capitalize }}</strong>
              </div>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('02-014', 1)">
              <v-btn to="/permission/gold-payment-permission" active-class="primary" rounded :min-width="120"
                style="height: 40px" small color="transparent" depressed>
                {{ $global.FilterPermissions("02-014").sub_title }}
              </v-btn>
            </v-col>
            <v-col cols="auto" class="px-1" v-if="$global.CheckAction('02-015', 1)">
              <v-btn to="/permission/gold-receipt-permission" color="primary" outlined rounded :min-width="120"
                style="height: 40px" small depressed>
                {{ $global.FilterPermissions("02-015").sub_title }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0">
          <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="returnBy"
            createBtnLabel="create gold payment permission" :data="rows" :header="header">
            <template v-slot:td="{ row, header }">

              <div v-if="header.key == 'actions'">
                <v-btn  @click="printMethod(row)" color="info"
                  :loading="printLoading.loading && printLoading.id == row.id" small icon>
                  <img src="@/assets/img/svg/pdf.svg" alt="Qarat-POS" />
                </v-btn>
                <v-btn @click="edit(row)" class="blue12 ma-1" x-small icon fab>
                  <v-icon color="blue1">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn @click="deleteMethod(row.id)" x-small class="ma-1" :class="'red12'"
                  :loading="isDeleteLoading && row.id == actionItem.id" icon fab>
                  <v-icon  color="error">mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="12" sm="12" class="py-0" v-if="pagination">
          <Pagination :limit="rowsNumber" :backValueMethod="changePage" :pagination="pagination" :isLoading="isLoading">
          </Pagination>
        </v-col>
      </v-row>
    </v-container>
    <VoucherMetal :type="1" :printAction="() => print = false" :print="print"  :printData="printData"
      :printStaticData="printStaticData" />
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
import Pagination from "@/components/Pagination";
import VoucherMetal from "../VoucherMetal.vue";
export default {
  name: "GoldPaymentPermissionTable",

  data: () => ({
    isLoading: true,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 25,
    searchText: null,
    from: null,
    to: null,
    isActive: 1,
    isDeleteLoading: false,
    deleteDialog: false,
    selectedRow: null,
    date: null,
    actionItem: Object,
    returnBy: "/permission/create-gold-payment-permission",
    printLoading: {
      loading: false,
      id: null,
      key: 'm_id'
    },
    printStaticData: Object,
    printData: [],
    print: false,
  }),
  components: {
    DynamicTable,
    Pagination,
    VoucherMetal
  },
  computed: {

  },
  watch: {
    isActive() {
      this.getData();
    }
  },
  mounted() {
    this.pageMainData();
    this.getData();
  },
  methods: {
    pageMainData() {
      document.title = `${this.$i18n.t("Qarat - ")}${this.$global.FilterPermissions("02-014").sub_title}`;
      this.header = [
        { text: "#", key: "id", type: 'text', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "supplier", key: "supplier_name", type: 'text', classes: "" },
        { text: "weight", key: "weight", type: 'weight', classes: "" },
        { text: "weight in caliber 24", key: "weight_in_24", type: 'weight', classes: "" },
        { text: "wages gram", key: "wages", type: 'non_zero_float', classes: "" },
        { text: "total wages", key: "total_wages", type: 'non_zero_float', classes: "" },
        { text: "", key: "actions", type: 'slot', classes: "", }
      ]
    },
    getData() {
      this.isLoading = true;
      if (this.$global.CheckAction("02-014", 1)) {
        this.$api.GET_METHOD(`payment_gold_permission?rows=${this.rowsNumber}&page=${this.page}&word=${this.searchText || ''}&active=${this.isActive}`).then((response) => {
          this.isLoading = false;
          this.rows = response.data.items.data;
          this.page = response.data.items.current_page;
          this.pagination = {
            page: response.data.items.current_page,
            totalPages: response.data.items.last_page,
            per_page: response.data.items.per_page,
            totalRows: response.data.items.total,
          };
        })
      }
      else {
        if (this.$global.CheckAction("02-015", 1)) {
          this.$router.push('/permission/gold-receipt-permission')
        }else{
          this.$router.push('/')
        }
      }
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getData()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
    edit(row) {
      this.$router.push(`/permission/gold-payment-permission/${row.id}`)
    },
    deleteMethod(id) {
      this.actionItem.id = id
      this.isDeleteLoading = true;
      this.deleteDialog = false;
      this.$api.POST_METHOD(`payment_gold_permission/${id}`,{_method : 'DELETE'}).then((response) => {
        this.isDeleteLoading = false
        if (response.check) {
          const getIndex = this.rows.findIndex((row) => row.id == id);
          this.rows.splice(getIndex, 1);
          this.actionItem = Object;
        }
      })
    },
    printMethod(row) {
      this.printLoading.loading = true;
      this.printLoading.id = row.id;
      this.$api.GET_METHOD(`payment_gold_permission/${row.id}`).then((response) => {
        this.printLoading.loading = false;
        if (response.check) {
          const { supplier_id, customer_id, treasury_gold_id, account_tree_id } = response?.data?.data || {}
          response.data.data.action_type = supplier_id ? 1 : customer_id ? 2 : treasury_gold_id ? 3 : account_tree_id ? 4 : 0;
          const supplier = response.data.suppliers.find((supplier) => supplier.id === supplier_id) || Object
          const customer = response.data.customers.find((customer) => customer.id === customer_id) || Object
          const account = response.data.account_trees.find((account) => account.id === account_tree_id) || Object
          const treasury = response.data.treasury_gold.find((treasury) => treasury.id === treasury_gold_id) || Object
          response.data.data.supplier_vat_register_number = supplier.vat_register_number;
          response.data.data.supplier_mobile = supplier.mobile;
          response.data.data.customer_vat_register_number = customer.vat_register_number;
          response.data.data.customer_mobile = customer.mobile;
          response.data.data.account_tree_name = account.name;
          response.data.data.account_tree_code = account.account_code;
          response.data.data.treasury_name = treasury.name;
          this.printStaticData = response.data.data;
          this.printData = response.data.details || [];
          this.print = true;
          setTimeout(() => {
            this.print = false;
            this.printLoading.id = null;
            this.printStaticData = Object;
          }, 1000);
        }
      });
    },
  },
};
</script>
