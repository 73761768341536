<template>
   <div>

      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
         :paginate-elements-by-height="$store.state.Settings.windowSize.x" filename="كشف حساب  عيارات" :pdf-quality="3"
         :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
         :pdf-content-width="$store.state.Settings.windowSize.y" ref="html2Pdf">
         <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
            <PrintPDF :header="tableHeader" :maxPDFRows="35" :data="printData" :header_slot="false" :content_slot="false"
               :footer_slot="false" :showGroupHeader="false" :groupHeader="false" :footerData="[]"
               :customize_header_footer="false" :printStaticData="Object">

               <template v-slot:pdf_content>

                  <div class="print_title">
                     <div> {{ type == 1 ? 'صـرف مـعـدن مشغول' : 'قبض مـعـدن مشغول' }}</div>
                     <div> {{ type == 1 ? 'METAL PAYMENT' : 'METAL RECEIPT' }}</div>
                  </div>


                  <div style="display: flex;justify-content: space-between;">
                     <table class="metal_voucher master" style="width:68% !important">
                        <tr>
                           <td>{{ $t('account number') }}</td>
                           <td>{{ '-' }}</td>
                           <td style="background: #e6ecff !important;">رقم الفاتورة /.No I</td>
                           <td>{{ printStaticData.id }}</td>
                        </tr>
                        <tr>
                           <td>
                              <template v-if="printStaticData.action_type == 1">
                                 اسم المورد <br /> Supplier Name
                              </template>
                              <template v-if="printStaticData.action_type == 2">
                                 اسم العميل <br /> Customer Name
                              </template>
                              <template v-if="printStaticData.action_type == 3">
                                 اسم الخزينة <br /> Treasury Name
                              </template>
                              <template v-if="printStaticData.action_type == 4">
                                 اسم الحساب <br /> Account Name
                              </template>
                           </td>
                           <td colspan="3">
                              <template v-if="printStaticData.action_type == 1">
                                 {{ printStaticData.supplier_name || '-' }}
                              </template>
                              <template v-if="printStaticData.action_type == 2">
                                 {{ printStaticData.customer_name || '-' }}
                              </template>
                              <template v-if="printStaticData.action_type == 3">
                                 {{ printStaticData.treasury_name || '-' }}
                              </template>
                              <template v-if="printStaticData.action_type == 4">
                                 {{ printStaticData.account_tree_code }} - {{ printStaticData.account_tree_name }}
                              </template>
                           </td>
                        </tr>
                        <tr v-if="printStaticData.action_type == 1 || printStaticData.action_type == 2">
                           <td>
                              {{ $t('mobile number') }}
                           </td>
                           <td colspan="3">
                              {{ printStaticData.supplier_mobile || printStaticData.customer_mobile || '-' }}
                           </td>
                        </tr>
                        <tr v-if="printStaticData.action_type == 1 || printStaticData.action_type == 2">
                           <td>
                              {{ $t('vat number') }}
                           </td>
                           <td colspan="3">
                              {{ printStaticData.supplier_vat_register_number ||
                                 printStaticData.customer_vat_register_number || '-' }}
                           </td>
                        </tr>
                     </table>
                     <table class="metal_voucher" style="width:30% !important">
                        <tr>
                           <td>{{ 'التاريخ' }}</td>
                           <td>{{ printStaticData.add_date | dateAr }}</td>
                           <td>{{ 'Date' }}</td>
                        </tr>
                        <tr>
                           <td>{{ 'الموافق' }}</td>
                           <td>{{ '-' }}</td>
                           <td>{{ 'Date H' }}</td>
                        </tr>
                        <tr>
                           <td>{{ 'الوقت' }}</td>
                           <td>{{ printStaticData.add_date | selectTime }}</td>
                           <td>{{ 'Time' }}</td>
                        </tr>
                     </table>
                  </div>


                  <table class="metal_voucher">
                     <tr>
                        <th>
                           No <br /> م
                        </th>
                        <th>
                           STOCK NAME
                           <br />
                           اسم الصنف
                        </th>
                        <th>
                           WEIGHT
                           <br />
                           الوزن
                        </th>
                        <th>
                           PURITY
                           <br />
                           العيار
                        </th>
                        <th>
                           Making Charge
                           <br />
                           سعر الاجور
                        </th>
                        <th>
                           Total Making
                           <br />
                           اجمالي الاجور
                        </th>
                        <th>
                           Pieces Count
                           <br />
                        عدد القطع
                        </th>
                     </tr>

                     <tr v-for="(detail, index) in printData" :key="index">
                        <td>{{ index+ 1 }}</td>
                        <td>{{ detail.sub_category_name }}</td>
                        <td>{{ detail.weight | nonZero }}</td>
                        <td>{{ detail.category_name || '-' }}</td>
                        <td>{{ detail.wages | nonZero }}</td>
                        <td>{{ detail.total_wages | nonZero }}</td>
                        <td>{{ detail.pieces_count  }}</td>
                     </tr>
                     <tr>
                        <td>{{ $t('Total') }}</td>
                        <td>-</td>
                        <td>{{ footerTotals?.totalWeight || 0 | nonZero }}</td>
                        <td>-</td>
                        <td>{{ footerTotals?.totalWages || 0 | nonZero }}</td>
                        <td>{{ footerTotals?.totalMaking || 0 | nonZero }}</td>
                        <td>{{ footerTotals?.piecesCount || 0  }}</td>
                     </tr>
                  </table>

                  <table class="metal_voucher">
                     <tr>
                        <th>
                           البيان / Des
                        </th>
                     </tr>
                     <tr>
                        <td style="background-color: #f8f9fd !important;">
                           {{ printStaticData.description || '-' }}
                        </td>
                     </tr>
                  </table>


                  <div class="print_title">
                     TRANSCATION SUMMARY / الملخص
                  </div>
                  <table class="metal_voucher">
                     <tr>
                        <th>
                           Silver
                        </th>
                        <th>
                           24k
                        </th>
                        <th>
                           22k
                        </th>
                        <th>
                           21k
                        </th>
                        <th>
                           18k
                        </th>
                     </tr>
                     <tr>
                        <td style="background-color: #f8f9fd !important;">
                           {{ totalCaliberWeight(925) | nonZero }}
                        </td>
                        <td>
                           {{ totalCaliberWeight(24) | nonZero }}
                        </td>
                        <td>
                           {{ totalCaliberWeight(22) | nonZero }}
                        </td>
                        <td>
                           {{ totalCaliberWeight(21) | nonZero }}
                        </td>
                        <td>
                           {{ totalCaliberWeight(18) | nonZero }}
                        </td>
                     </tr>
                  </table>


               </template>
            </PrintPDF>
         </section>
      </vue-html2pdf>
   </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import PrintPDF from '@/components/PrintPDF.vue';
export default {
   name: "VoucherMetal",
   props: {
      printAction: { type: Function },
      print: { default: false },
      printData: { default: [] },
      printStaticData: { default: Object },
      title: { default: '' },
      type: { default: '' },
      footerData: { default: Object },
   },
   computed: {
      totalCaliberWeight() {
         return (category_id) => {
            return this.printData.reduce((oldValue, newValue) => {
               let sumition = +oldValue + (+newValue.category_id === category_id ? +newValue.weight : 0);
               return sumition;
            }, 0);
         }
      },
      footerTotals() {
         return this.printData.reduce(
            (acc, detail) => {
               acc.totalWeight += +detail.weight || 0;
               acc.totalWages += +detail.wages || 0;
               acc.totalMaking += +detail.total_wages || 0;
               acc.piecesCount += +detail.pieces_count || 0;
               return acc;
            },
            { totalWeight: 0, totalWages: 0, totalMaking: 0,piecesCount : 0 }
         );
      }
   },
   components: {
      VueHtml2pdf,
      PrintPDF
   },
   watch: {
      print() {
         if (this.print) {
            this.$refs.html2Pdf.generatePdf();
         }
      },
   },
   data: () => ({
      tableHeader: [],
   }),
   methods: {

   },
   mounted() {

   },

};
</script>

<style lang="scss" >
.metal_voucher {
   margin: 20px 0;
   border-collapse: collapse;
   border: 2px solid #e9e8ee;
   //border-bottom: 8px solid #28aae1;
   //border-right: 8px solid #28aae1;
   background: #fff !important;
   width: 100%;

   tr {
      th {
         z-index: 2;
         position: relative;
         padding: 5px !important;
         background: #e9e8ee !important;
         border: 1px solid #ddd !important;
         font-size: 11px !important;
      }

      td {
         padding: 5px !important;
         // font-weight: 600;
         border-bottom: 1px solid #ddd !important;
         font-size: 12px !important;
         background: #f8f9fd !important;
         border-left: 1px solid #eee;
         text-align: center !important;
         &:nth-of-type(1) {
            // color: #666 !important;
            background: #e6ecff !important;
            padding: 5px !important;
            text-align: center !important;
         }

         &:nth-of-type(2) {
            text-align: center !important;
            color: #333 !important;
         }
      }
   }

   &.master {
      td {
         width: 25% !important;
      }
      tr:nth-of-type(1){
         td:nth-of-type(3){
            background: #e6ecff !important;
         }
      }
   }
}
</style>