<template>
  <v-form ref="form" v-model="valid" v-if="!isLoading" onsubmit="return false;" lazy-validation>
    <v-row justify="space-between" class="my-0">
      <v-col cols="12" class="d-flex justify-center align-center py-0 mb-3">
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="auto">
            <div class="subtitle-2 text-center me-3 text--disabled">
              <strong>{{ $t('used purchases') | capitalize }} . {{ $t('create used purchases') }}</strong>
            </div>
          </v-col>
          <v-col cols="12" md="auto">
            <v-btn color="red12" class="error--text mx-2" :width="120" depressed @click="exitDialog = true">
              {{ $t('cancel') }}
              <v-icon right>mdi-close</v-icon>
            </v-btn>
            <v-btn color="success" :disabled="!(valid && details.length && form.active != 0)" :width="120"
              :loading="loading" depressed @click="makeInvoice">
              {{ $t('save') }}
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card class="shadow rounded-lg justify-center pa-5">
      <v-row justify="end">

        <v-col cols="12" md="6">
          <v-row>
            <GenericInput type="text" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
              name="customer_name" label="PleaseEnterThecustomerName" :value="form.customer_name"
              @input="form.customer_name = $event" :required="true" :isLoading="false" :cols="[12, 6, 6]" />

            <GenericInput type="text" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
              name="customer_mobile" label="mobileNumber" :value="form.customer_mobile"
              @input="form.customer_mobile = $event" :required="true" :isLoading="false" :cols="[12, 6, 6]" />
            <v-col cols="12" class="pt-1 px-0">
              <v-divider />
            </v-col>
            <v-card outlined class="pa-5 py-7 pb-2">
              <v-row>

                <GenericInput type="date" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
                  name="action_date" label="action date" :value="form.action_date" @input="form.action_date = $event"
                  :required="true" :isLoading="false" :cols="[12, 6, 6]" />

                <GenericInput type="select" :lookups="nationalities" :value="form.nationality_id"
                  @input="form.nationality_id = $event" label="nationality" :multi="false" :required="false"
                  selected_label="name" selected_prop="nationality_id" :isLoading="false" :cols="[12, 6, 6]" />

                <GenericInput type="select" :lookups="identity_types" :value="form.identity_type_id"
                  @input="form.identity_type_id = $event" label="identity type" :multi="false" :required="false"
                  selected_label="name" selected_prop="identity_type_id" :isLoading="false" :cols="[12, 6, 6]" />

                <GenericInput type="text" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
                  name="id_number" label="idNumber" :value="form.id_number" @input="form.id_number = $event"
                  :required="true" :isLoading="false" :cols="[12, 6, 6]" />

                <GenericInput type="date" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
                  name="identity_expire" label="identity expire" :value="form.identity_expire"
                  @input="form.identity_expire = $event" :isLoading="false" :cols="[12, 6, 6]" />

                <GenericInput type="text" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
                  name="version" label="version number" :value="form.version" @input="form.version = $event"
                  :required="true" :isLoading="false" :cols="[12, 6, 6]" />
                <v-col cols="12" sm="6" md="4" class="py-1">
                  <div class="d-flex align-center">
                    <label class="cursor_pointer"
                      :class="{ 'font-weight-bold primary--text': form.birth_date_type == 0 }"
                      @click="form.birth_date_type = 0">{{ $t('georgian') }}</label>
                    <v-switch v-model="form.birth_date_type" class="mx-2" inset />
                    <label class="cursor_pointer"
                      :class="{ 'font-weight-bold primary--text': form.birth_date_type == 1 }"
                      @click="form.birth_date_type = 1">{{ $t('hijri') }}</label>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="8" class="py-1" v-if="form.birth_date_type == 0">
                  <v-menu ref="menu" offset-x v-model="menu" :close-on-content-click="false"
                    transition="scale-transition" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field :rules="$global.requiredRule" outlined @blur="date = parseDate(dateFormatted)"
                        v-model="form.birth_date_m" rounded dense @change="menu = false" class="my-2 rounded-lg"
                        :label="$i18n.t('georgian date') + '*'" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                        v-on="on">
                        <template v-slot:append>
                          <div class="mt-1" style="width: 100px;" v-if="form.birth_date_h">{{ form.birth_date_h }}</div>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker reactive show-adjacent-months locale="ar-eg" v-model="form.birth_date_m"
                      :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                      @change="setDate" />
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="8" class="py-1" v-if="form.birth_date_type == 1">
                  <v-menu ref="menu" v-model="menuHijri" :close-on-content-click="false" transition="scale-transition"
                    offset-x min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field outlined :rules="$global.requiredRule" v-model="form.birth_date_h"
                        :label="$i18n.t('hijri date') + '*'" rounded dense @change="menuHijri = false"
                        class="my-2 rounded-lg" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
                        <template v-slot:append>
                          <div class="mt-1" style="width: 100px;" v-if="form.birth_date_m">{{ form.birth_date_m }}</div>
                        </template>
                      </v-text-field>
                    </template>
                    <v-hijri-date-picker v-model="form.birth_date_h" @change="menuHijri = false"
                      :active-picker.sync="activePicker" locale="ar" />
                  </v-menu>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="pt-1">
          <div v-if="isEdit" class="text-end mx-5 font-weight-bold">{{ $t('payment type') }} :
            <template v-if="payment_type_title">
              {{ $t(payment_type_title) }}
            </template>
            <template v-else>
              <span v-for="(type, i) in $global.getPayments(paymentsType)" :key="i">{{ type?.name }}
                <span v-if="i !== $global.getPayments(paymentsType).length - 1">, </span>
              </span>
            </template>
          </div>
          <v-card outlined class="pa-4">
            <v-row>
              <v-col cols="12" md="8">
                <v-card class="pa-1 borderedCard" min-height="115px" flat>
                  <h6 class="subtitle-1 font-weight-bold">{{ $t('attachments') }}</h6>
                  <v-row align="center">
                    <v-col cols="auto">
                      <v-card class="uploadCard">
                        <div>
                          <v-icon size="38" class="d-block text-center">mdi-upload</v-icon>
                          <div class="subtitle-2 text-center font-weight-bold">{{ $t('click to upload') }}
                          </div>
                        </div>
                        <input type="file" @change="onFilesChanged($event)" :key="fileKey" multiple
                          accept="image/jpeg,image/gif,image/png,image/x-eps" id="uploadFile">
                      </v-card>
                    </v-col>
                    <v-col cols="auto" class="imgContainer" v-for="(file, index) in attachments" :key="index">
                      <v-img max-width="115px" width="115px" max-height="115px"
                        :src="$store.state.endpointURL + file.file_path" alt="" />
                      <div class="caption text-center d-block text-truncate" style="max-width: 115px;">
                        {{ file.file_name }}
                      </div>
                      <v-btn icon @click="deleteAttachment(file, index, false)" :loading="deleteAttachmentLoading"
                        color="error"><v-icon>mdi-delete-circle</v-icon></v-btn>
                    </v-col>
                    <v-col cols="auto" class="imgContainer" v-for="(file, index) in new_attachments_base64"
                      :key="index">
                      <v-img max-width="115px" width="115px" max-height="115px" :src="file" alt="" />
                      <v-btn icon @click="deleteAttachment(file, index, true)" :loading="deleteAttachmentLoading"
                        color="error"><v-icon>mdi-delete-circle</v-icon></v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="pt-1">
                <PaymentMethodPurchase :getTypeMethod="getTypeMethod" :backValueMethod="setPayment"
                  :machineID="form.machine_id" :bankID="form.bank_id" :totalPrice="calculateSum('price')" />
                <v-spacer class="mb-5"></v-spacer>
                <BankModal v-if="payment_type_title" title="machine" :machines="machines" :machine="form.machine"
                  :block="true" :backValueMethod="setMachine" />
                <v-spacer class="mb-5"></v-spacer>
                <BankModal v-if="payBankCheck" title="bank" headerName="Change bank" :machines="banks"
                  :machine="form.bank" :backValueMethod="setBank" :block="true" class="mt-3" />
                <v-spacer class="mb-5"></v-spacer>
                <SellarModal block defaultLabel="choose bayer" :height="40" :sales_agents="sales_agents"
                  :sellar="salesAgent" :backValueMethod="setAgent" :checkChangeAgent="false" />
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <!-- <GenericInput type="file" :hideImage="true" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
          name="id_photo" label="identity image" :value="form.id_photo" @input="form.id_photo = $event"
          :required="false" :isLoading="false" :cols="[12, 3, 2]" />

        <GenericInput type="file" :hideImage="true" :solo="false" :outlined="true" :dense="true" classes="rounded-lg"
          name="item_photo" label="item image" :value="form.item_photo" @input="form.item_photo = $event"
          :required="false" :isLoading="false" :cols="[12, 3, 2]" /> -->

      </v-row>
    </v-card>
    <ExitConfirmationModal :dialog="exitDialog" :closeDialog="() => exitDialog = false" exit_to="/used-purchases" />
  </v-form>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import BankModal from "@/components/modals/BankModal.vue";
import ExitConfirmationModal from "@/components/modals/ExitConfirmationModal.vue";
import PaymentMethodPurchase from "@/components/modals/PaymentMethodPurchase.vue";
import SellarModal from "@/components/modals/SellarModal.vue";


export default {
  name: "UsedPurchaseForm",
  components: {
    SellarModal,
    GenericInput,
    ExitConfirmationModal,
    PaymentMethodPurchase,
    BankModal
  },
  computed: {
    translation() {
      return this.$store.state.Settings.translation;
    },
    calculateSum() {
      return (KEY) => {
        return this.details.reduce((oldValue, newValue) => {
          if (KEY in newValue) return +oldValue + +newValue[KEY]
          else return +oldValue
        }, 0);
      }
    },
    fileExtension() {
      if (this.input) {
        return this.$global.FILE_TYPE(this.input);
      } else {
        return null
      }
    },
    payBankCheck() {
      return (
        this.payment_type_title === 'transferPayment' || this.payment_type_title === 'transferAndCashPayment'
        || this.payment_type_title === 'paymentTransferAndBank'
        && this.banks?.length > 0
      );
    },
    machineCheck() {
      return (
        this.payment_type_title !== 'transferPayment' || this.payment_type_title === 'transferAndCashPayment'
        || this.payment_type_title === 'paymentTransferAndBank'
        && this.banks?.length > 0
      );
    },
    isEdit() {
      return this.$route.params.id
    }
  },
  props: {
    isLoading: { default: true },
    sales_agents: { default: [] },
    nationalities: { default: [] },
    identity_types: { default: [] },
    machines: { default: [] },
    banks: { default: [] },
    selectedAgent: { default: {} },
    master: { default: {} },
    details: { default: [] },
    paymentsType: { default: [] },
  },
  data: () => ({
    exitDialog: false,
    dateIsLoading: false,
    dialog: false,
    valid: false,
    menu: false,
    menuHijri: false,
    activePicker: null,
    payment_type_title: null,
    activeStep: 1,
    salesAgent: Object,
    form: {
      id_number: null,
      customer_mobile: null,
      customer_name: null,
      items: [],
      sales_agent_id: null,
      notes: null,
      id_photo: null,
      birth_date_type: 0,
      birth_date_h: null,
      birth_date_m: null,
      action_date: null,
      nationality_id: 1,
      identity_type_id: 1,
      payment_type: null,
      item_photo: null,
      machine_id: null,
      bank_id: null,
      identity_expire: null,
      version: null,
      machine: {},
      bank: {},
      payments: [],
    },
    loading: false,
    fieldRule: [
      v => !!v || this.translation['Field is required']
    ],
    printID: 0,
    fileKey: 1,
    printContent: {},
    printLoading: false,
    deleteAttachmentLoading: false,
    new_attachments: [],
    new_attachments_base64: [],
    attachments: [],
  }),

  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    'form.birth_date_m'() {
      if (this.form.birth_date_type == 0) {
        this.form.birth_date_h = this.form.birth_date_m ? this.$global.GregorianToHijri(this.form.birth_date_m) : null;
      }
    },
    'form.birth_date_h'() {
      if (this.form.birth_date_type == 1) {
        this.form.birth_date_m = null;
        let hijriDate = this.form.birth_date_h?.split('/').reverse().join('-');
        this.dateIsLoading = true
        this.$api.GET_METHOD(`get_gregorian_data/${hijriDate}`).then((response) => {
          this.dateIsLoading = false;
          if (response.check) {
            this.form.birth_date_m = response.data.hijri_date
          }
        })
      }
    },
    'form.machine_id'() {
      if (this.form.machine_id) {
        this.form.machine = this.machines.find(({ id }) => id == this.form.machine_id) || {};
      }
    },
    'form.bank_id'() {
      if (this.form.bank_id) {
        this.form.bank = this.banks.find(({ id }) => id == this.form.bank_id) || {};
      }
    },
    payBankCheck() {
      if (!this.payBankCheck) {
        this.form.bank_id = null;
      }
    },
    isLoading() {
      if (!this.isLoading && this.master.id) {
        this.salesAgent = this.selectedAgent
        this.form = { ...this.master }
        this.master.id_photo ? this.attachments.push({ file_path: this.master.id_photo }) : null;
        this.master.item_photo ? this.attachments.push({ file_path: this.master.item_photo }) : null;
        this.getAttachments();
      }
    },
    details: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (oldVal.length) {
          this.form.payments = []
        }
      },
      deep: true
    }
  },
  mounted() {
    this.salesAgent = this.selectedAgent
    this.form.sales_agent_id = this.selectedAgent.id
    this.form.action_date = this.form.action_date || this.$global.GetCurrentDate();
  },
  methods: {
    openDialog() {
      this.dialog = true
    },
    setWeight(returnedWeight) {
      this.form.weight = returnedWeight;
    },
    setPrice(returnedPrice) {
      this.form.price = returnedPrice;
    },

    makeInvoice() {
      this.$refs.form.validate();
      let hijriDate = this.form.birth_date_h?.split('/')?.reverse()?.join('-');
      let requestBody = {
        id_number: this.form.id_number,
        customer_mobile: this.form.customer_mobile,
        customer_name: this.form.customer_name,
        sales_agent_id: this.form.sales_agent_id,
        notes: this.form.notes,
        id_photo: String(this.form.id_photo)?.substring(0, 4) == 'data' ? this.form.id_photo : null,
        birth_date_type: this.form.birth_date_type,
        birth_date_h: hijriDate,
        birth_date_m: this.form.birth_date_m,
        nationality_id: this.form.nationality_id,
        identity_type_id: this.form.identity_type_id,
        action_date: this.form.action_date,
        item_photo: String(this.form.item_photo)?.substring(0, 4) == 'data' ? this.form.item_photo : null,
        items: this.details || [],
        machine_id: this.form.machine_id,
        bank_id: this.form.bank_id,
        payments: this.form.payments,
        version: this.form.version,
        identity_expire: this.form.identity_expire,
      }

      if (this.$refs.form.validate() && requestBody.sales_agent_id && requestBody.payments.length) {
        this.loading = true;
        let endPoint = this.form.id ? `update_used_purchase/${this.form.id}` : "create_used_purchase"
        let successMessage = this.form.id ? this.$i18n.t('updated successfully') : this.$i18n.t('Invoice Created Successfully')
        this.$api.POST_METHOD(endPoint, requestBody, true, successMessage).then((response) => {
          this.loading = false;
          if (response.check) {
            this.sendAttachments(response.data);
            this.dialog = false
            this.$router.push('/used-purchases')
            const src = `src="${this.$api.serverUrl}export_invoice_used_purchase/${response.data}?type=image&clientID=${this.$store.state.clientID}"`;
            this.$api.PrintInvoice(src, response.data);
          }
        })
      }
      else {
        this.$swal.fire({
          title: this.$t("Please Check"),
          text: requestBody.sales_agent_id ? requestBody.payments.length ? this.$t("Required Fields") : this.$t("select payment method") : this.$t("choose bayer"),
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date?.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date?.split('-')
      return `${year}-${month}-${day}`
    },
    setAgent(returnedValue) {
      this.salesAgent = returnedValue;
      this.form.sales_agent_id = returnedValue.id
    },
    setDate(date) {
      this.$refs.menu.save(date)
    },
    setPayment(returnedValue) {
      this.form.payments = returnedValue;
    },
    setMachine(returnedValue) {
      this.form.machine_id = returnedValue?.id;
    },
    setBank(returnedValue) {
      this.form.bank_id = returnedValue.id;
    },
    getTypeMethod(type) {
      this.payment_type_title = type;
    },
    getAttachments() {
      let requestBody = { screen_code: '04-008', m_id: this.form.id || +this.$route.params.id }
      this.$api.GET_METHOD(`attachment?screen_code=${requestBody.screen_code}&m_id=${requestBody.m_id}`).then((response) => {
        if (response.check) {
          response.data?.files.forEach(file => {
            file.file_path = '/upload/04-008/' + file.file_path
            this.attachments.push(file)
          });
        }
      })
    },
    deleteAttachment(file, index, newFile) {
      this.fileKey++;
      if (file.screen_file_id) {
        this.deleteAttachmentLoading = true;
        let requestBody = { screen_code: '04-008', m_id: this.form.id || +this.$route.params.id, screen_file_id: file.screen_file_id }
        this.$api.POST_METHOD(`attachment_toggle_active/${requestBody.screen_file_id}`, requestBody).then((response) => {
          this.deleteAttachmentLoading = false;
          if (response.check) {
            this.attachments.splice(index, 1);
          }
        })
      } else {
        if (newFile) {
          this.new_attachments_base64.splice(index, 1)
          this.new_attachments.splice(index, 1)
        } else {
          this.attachments.splice(index, 1);
        }
      }
    },
    onFilesChanged(event) {
      const files = event.target.files;
      Array.from(files).forEach(file => {
        this.new_attachments.push(file)
        const reader = new FileReader();
        reader.onload = (e) => {
          this.new_attachments_base64.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    async sendAttachments(id) {
      if (id && this.new_attachments.length) {
        let attachments = this.new_attachments || []
        Object.keys(attachments).forEach((file) => {
          let file_name = attachments[file].name.split(/[\\/]/g).pop().split('.')[0];
          this.loading = true;
          let requestBody = { 'm_id': id, file: attachments[file], 'screen_code': '04-008', file_name: file_name }
          this.$api.POST_METHOD_MULTIPART(`attachment`, requestBody, false, 'attachment uploaded')
            .then(() => {
              this.loading = false;
            })
        });
        this.fileKey++
      }
    },
  },

};
</script>



<style lang="scss">
.borderedCard {
  position: relative;
  border-radius: 8px;

  &:before {
    content: "";
    position: absolute;
    border: 3px dashed rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
  }
}

.imgContainer {
  position: relative;

  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 55;
    display: none;
  }

  &:hover {
    button {
      display: block;
    }
  }
}
</style>
