<template>
  <v-dialog v-model="dialog" persistent max-width="1100">
    <v-card relative>
      <v-btn absolute class="ma-2" :style="$vuetify.rtl ? 'left: 0' : 'right: 0'" small fab color="grey lighten-1" text
        @click="close">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>

      <v-container>
        <v-row justify="center" align="end">
          <v-col cols="8">
            <v-card :height="$store.state.Settings.windowSize.y - 155" class="overflow-y-auto pa-3" outlined>
              <v-col cols="12" class="pa-0 d-flex align-center">
                <GenericInput type="text" :clearable="true" :outlined="true" :value="barcode_id"
                  @input="barcode_id = $event" label="barcode number" placeholder="search with barcode"
                  icon="mdi-barcode-scan" :keydownEnter="searchByCode" :isLoading="isLoading" :cols="[11, 11, 11]" />
                <v-btn @click="searchByCode" fab color="primary" x-small :disabled="isLoading" :loading="isLoading">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>

              <DynamicTable v-if="computedRows.length" classes="shadow-none py-0" :searchByDateRange="false"
                :hideExtinctions="true" :showHeader="false" :viewTitle="false" :data="computedRows"
                :header="tableHeader">
                <template v-slot:td="{ index, header }">
                  <v-btn @click="deleteRow(index)" v-if="header.key === 'delete'" icon><v-icon
                      color="error">mdi-trash-can</v-icon></v-btn>
                </template>
              </DynamicTable>
              <img v-else src="@/assets/img/gif/barcode-scan2.gif" height="250" class="d-block mx-auto mt-16" alt="">
            </v-card>
            <div class="caption font-weight-bold mt-2 gray7--text">
              <v-icon color="gray7" size="16">mdi-alert</v-icon>
              {{ $t("please select item with same category") }}
            </div>
          </v-col>
          <v-col cols="4">
            <v-card flat>

              <div class="subtitle-2 mb-1 d-flex justify-space-between">
                <div>{{ $t('total weight') }}:</div>
                <div class="font-weight-bold">{{ calculateSum('weight') | float }}</div>
              </div>
              <div class="subtitle-2 mb-1 d-flex justify-space-between">
                <div>{{ $t('min sell price') }}:</div>
                <div class="font-weight-bold">{{ calculateSum('minPrice') | float }}</div>
              </div>
              <div class="subtitle-2 mb-1 d-flex justify-space-between">
                <div>{{ $t('sell price') }}:</div>
                <div class="font-weight-bold">{{ calculateSum('sell_price') | float }}</div>
              </div>
              <div class="subtitle-2 mb-1 d-flex justify-space-between">
                <div>{{ $t('gram price') }}:</div>
                <div class="font-weight-bold">{{ gramPrice | float }}</div>
              </div>
              <v-divider />
              <h5 class="my-5">{{ $t("Total including tax") }} :</h5>
              <NumberPad :backValueMethod="setPrice" :value="price == 0 ? '' : String(price)" />
              <v-col cols="12" class="d-flex justify-end pa-0">
                <v-btn class="me-2" depressed min-width="100" color="error" @click="close">
                  {{ $t("cancel") }}</v-btn>
                <v-btn class="ms-2" depressed min-width="100" color="success" @click="save"
                  :disabled="!(totalPriceValidation)">
                  {{ $t("save") }}</v-btn>
              </v-col>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

    </v-card>
  </v-dialog>
</template>


<script>
import NumberPad from "../NumberPad.vue";
import GenericInput from "../GenericInput.vue";
import DynamicTable from '../DynamicTable.vue';
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "BarcodeGroupModal",
  props: {
    closeDialog: { default: Function },
    loading: { default: false },
    gold_price: { default: {} },
    dialog: { default: false },
    vats: { default: {} },
  },
  data() {
    return {
      barcode_id: null,
      isLoading: false,
      price: 0,
      tableRows: [],
    };
  },
  mounted() { },
  computed: {
    ...mapState(["bill"]),
    totalPriceValidation() {
      let minimumPrice = this.calculateSum('minPrice');
      return +this.price >= +minimumPrice && this.tableRows.length > 0
    },
    gramPrice() {
      let total_weight = this.calculateSum('weight') || 0;
      return this.tableRows.length ? +this.price / +total_weight : 0
    },
    groupID() {
      return this.tableRows.map(({ itemCode }) => itemCode).join(',')
    },
    tableHeader() {
      return [
        { text: "#", key: "itemCode", type: 'text' },
        { text: "item", key: "itemTitle", type: 'text' },
        { text: "category", key: "category_title", type: 'text' },
        { text: "min sell price", key: "minPrice", type: 'non_zero_float' },
        { text: "sell price", key: "sell_price", type: 'non_zero_float' },
        { text: "gram price", key: "caliber_price", type: 'non_zero_float' },
        { text: "price after entry", key: "price", type: 'non_zero_float' },
        { text: "delete", key: "delete", type: 'slot' },
      ]
    },
    calculateSum() {
      return (KEY) => {
        return this.tableRows.reduce((oldValue, newValue) => {
          if (KEY in newValue) return +oldValue + +newValue[KEY]
          else return +oldValue
        }, 0);
      }
    },
    computedRows() {
      this.tableRows.forEach((row) => {
        row.group_id = this.groupID;
        row = this.prepareDetails(row);
      })
      return this.tableRows;
    }
  },
  watch: {},
  methods: {
    ...mapActions(["addBillAction"]),
    searchByCode() {
      if (!this.barcode_id) { this.$api.ShowAlert("info", this.$t("enter barcode"), null); return; }
      let existInInvoice = this.bill.some(({ itemCode, parent_item_code }) => itemCode == this.barcode_id || parent_item_code == this.barcode_id)
      let existInTable = this.tableRows.some(({ itemCode, parent_item_code }) => itemCode == this.barcode_id || parent_item_code == this.barcode_id)
      if (existInInvoice || existInTable) {
        this.$api.ShowAlert("info", this.$t("item is used"), null)
      } else {
        this.isLoading = true;
        this.$api.GET_METHOD(`search_item?code=${this.barcode_id}&type=sales`).then(({ check, data }) => {
          this.isLoading = false;
          if (check) {
            if (this.tableRows.length && this.tableRows?.at(0)?.categoryId !== data.categoryId) {
              this.$api.ShowAlert("info", this.$t("please select item with same category"), '');
              this.barcode_id = null;
              return;
            }
            data.sell_price = data.price;
            data.price = 0
            data.itemCode = data.id;
            this.tableRows.push(data)
            this.barcode_id = null;
          } else {
            this.$api.ShowAlert("info", this.$t("No Results"), '')
          }
        })
      }
    },
    setPrice(price) {
      this.price = +price;
    },
    save() {
      this.computedRows.forEach((detail, index) => {
        this.addBillAction({
          item_code: +detail.id,
          itemTitle: detail.itemTitle,
          categoryId: +detail.categoryId,
          subCategoryId: +detail.subCategoryId,
          gold_weight: +detail.gold_weight || 0,
          diamond_weight: +detail.diamond_weight || 0,
          stone_weight: +detail.stone_weight || 0,
          silver_weight: +detail.silver_weight || 0,
          minPrice: +detail.minPrice,
          priceWithoutVat: +detail.price_without_vat,
          vat: +detail.vat,
          price: +detail.price,
          countPieces: +detail.countPieces,
          shap_id: +detail.shap_id,
          quality_id: +detail.quality_id,
          clarity_id: +detail.clarity_id,
          stone_id: +detail.stone_id,
          color_id: +detail.color_id,
          count_diamonds: +detail.count_diamonds,
          caliber_id: +detail.caliber_id,
          parent_item_code: +detail.parent_item_code,
          caliber_price: +detail.caliber_price || 0,
          group_id: this.groupID,
          lobes: null,
        }).finally(() => {
          if (index + 1 === this.tableRows.length) {
            this.close();
          }
        })
      });
    },
    deleteRow(index) {
      this.tableRows.splice(index, 1);
    },
    close() {
      this.price = 0;
      this.tableRows = [];
      this.closeDialog(false);
    },
    prepareDetails(detail) {
      detail.price = this.gramPrice * detail.weight;
      detail.price_without_vat = +detail.price / (1 + +this.vats[detail.categoryId]);
      detail.vat = +detail.price - +detail.price_without_vat;
      switch (+detail.categoryId) {
        case 18: case 21: case 22: case 24:
          detail.caliber_price = this.gramPrice;
          detail.category_title = this.$t('caliber') + ' ' + detail.categoryId;
          detail.gold_weight = detail.weight;
          break;
        case 25:
          detail.category_title = this.$t('diamond');
          detail.diamond_weight = detail.weight;
          break;
        case 26:
          detail.category_title = this.$t('stones');
          detail.stone_weight = detail.weight;
          break;
        case 50: detail.category_title = this.$t('services'); break;
        case 925:
          detail.category_title = this.$t('silver');
          detail.silver_weight = detail.weight;
          break;
        default: detail.caliber_price = 0; break;
      }

    }
  },
  components: { NumberPad, GenericInput, DynamicTable },
};
</script>
<style scoped lang="scss"></style>